<template>
  <chart-widget-wrapper
    class="org-unit-ranking-widget"
    aid="org-unit-ranking-widget"
    :options="wrapperOptions"
    :loading="loading"
    :error="error"
    slot-wrapper-class=""
    hide-actions
    is-grid
  >
    <template #header>
      <div class="header-wrapper">
        <div class="selects-wrapper q-pl-md q-mr-auto">
          <runai-select aid="ranking-entity-select" :options="entityOptions" v-model="selectedEntityOption" />
          <runai-select aid="ranking-metric-select" :options="metricOptions" v-model="selectedMetricOption" />
          <runai-select aid="ranking-time-frame-select" :options="timeFrameOptions" v-model="selectedTimeFrameOption" />
        </div>
        <widget-link-btn
          class="q-mr-md"
          aid="ranking-radirect-btn"
          v-if="wrapperOptions.linkText"
          :link-text="wrapperOptions.linkText"
          :entity-type="wrapperOptions.entityType"
          @click="redirectToEntityIndex"
        />
      </div>
    </template>
    <runai-table
      :rows="rankingRecords"
      :columns="columns"
      :filter-by="filterBy"
      :loading="loading"
      disable-selection
      no-shadow
      :hide-pagination="!empty"
      :bordered="false"
      aid="ranking-table"
    >
      <template #no-data>
        <runai-table-no-data
          :filter-by="filterBy"
          entity-name="org unit"
          :custom-message="emptyStateMessage"
          :show-create-btn="false"
          :show-filter-icon-and-button="false"
      /></template>
    </runai-table>
  </chart-widget-wrapper>
</template>
<script lang="ts">
import { computed, defineComponent, type PropType, ref, watch } from "vue";
import { RunaiTable } from "@/components/common";
import { RunaiTableNoData } from "@/components/common/runai-table/runai-table-no-data";
import { ChartWidgetWrapper } from "@/components/dashboard-v2/widgets/common/widget-wrapper/chart-widget-wrapper";
import { useRouter } from "vue-router";
import type { IFilterBy } from "@/models/filter.model";
import { EWidgetEntity } from "@/models/chart.model";
import { orgUnitService } from "@/services/control-plane/org-unit.service/org-unit.service";
import { useRefresh } from "@/composables/use-refresh.composable";
import { EIntervalLabels } from "@/models/interval.model";
import { RunaiSelect } from "@/components/common/runai-select";
import {
  EOrgUnitEntityRankingValue,
  EOrgUnitOverTimeColumnName,
  EOrgUnitRankingMetricValue,
  type IOrgUnitEntitySelectOption,
  type IOrgUnitRankingMetricSelectOption,
  type IOrgUnitTimeFrameSelectOption,
  ORG_UNIT_RANKING_ENTITY_SELECT_OPTIONS,
  ORG_UNIT_RANKING_METRIC_SELECT_OPTIONS,
  ORG_UNIT_TIME_FRAME_SELECT_OPTIONS,
} from "@/models/org-unit.model";
import { WidgetLinkBtn } from "@/components/dashboard-v2/widgets/common/buttons/widget-link-btn";
import {
  departmentsRankingColumns,
  EOrgUnitRankingColumnName,
  type IOrgUnitRankingTable,
  projectsRankingColumns,
} from "@/table-models/org-unit.table-model";
import { PROJECT_ROUTE_NAMES } from "@/router/project.routes/project.routes.names";
import { DEPARTMENT_ROUTE_NAMES } from "@/router/department.routes/department.routes.names";
import { orgUnitUtil } from "@/utils/org-unit.util";
import { ETableFilters, HIGHLIGHT_COLUMN_CONFIG } from "@/models/table.model";
import { filterUtil } from "@/utils/filter.util/filter.util";
import { WorkloadSortFilterFields } from "@/swagger-models/workloads-client";
import { workloadService } from "@/services/cluster/workload.service/workload.service";
import { filterService } from "@/services/filter.service/filter.service";
import type { IProjectTableFilterBy } from "@/models/project.model";
import type { IDepartmentTableFilterBy } from "@/models/department.model";

export default defineComponent({
  name: "org-unit-ranking-widget",
  components: { WidgetLinkBtn, RunaiSelect, RunaiTableNoData, ChartWidgetWrapper, RunaiTable },
  props: {
    clusterId: {
      type: String as PropType<string>,
      required: true,
    },
    nodePoolName: {
      type: String as PropType<string>,
      required: false,
    },
  },
  setup(props) {
    const router = useRouter();

    const loading = ref(true);
    const error = ref(false);
    const rankingRecords = ref<IOrgUnitRankingTable[]>([]);
    const entityOptions = ref<IOrgUnitEntitySelectOption[]>(ORG_UNIT_RANKING_ENTITY_SELECT_OPTIONS);
    const metricOptions = ref<IOrgUnitRankingMetricSelectOption[]>(ORG_UNIT_RANKING_METRIC_SELECT_OPTIONS);
    const timeFrameOptions = ref<IOrgUnitTimeFrameSelectOption[]>(ORG_UNIT_TIME_FRAME_SELECT_OPTIONS);
    const selectedEntityOption = ref<IOrgUnitEntitySelectOption>(ORG_UNIT_RANKING_ENTITY_SELECT_OPTIONS[0]);
    const selectedMetricOption = ref<IOrgUnitRankingMetricSelectOption>(ORG_UNIT_RANKING_METRIC_SELECT_OPTIONS[0]);
    const selectedTimeFrameOption = ref<IOrgUnitTimeFrameSelectOption>(ORG_UNIT_TIME_FRAME_SELECT_OPTIONS[1]);
    const filterBy = ref<IFilterBy>({
      descending: true,
      sortBy: EOrgUnitRankingColumnName.AllocationValue,
    });

    const getEntityRanking = async () => {
      try {
        const { metricType, order } = orgUnitUtil.mapRankingMetricToMetricTypeAndOrder(
          selectedMetricOption.value.value,
          selectedTimeFrameOption.value.value,
        );
        error.value = false;
        rankingRecords.value = await getEntityFunc.value(metricType, order, props.clusterId, props.nodePoolName);
      } catch (err) {
        error.value = true;
      } finally {
        loading.value = false;
      }
    };

    const enrichRecordsWithWorkloadsCount = async () => {
      try {
        const workloadCountPromises = rankingRecords.value.map(async (record) => {
          record.workloadsCount = await workloadService.getWorkloadsCount(getWorkloadCountFilters(record));
        });
        await Promise.all(workloadCountPromises);
      } catch (err) {
        console.error(`Failed to enrich records with workloads count: ${err}`);
      }
    };

    const redirectToEntityIndex = () => {
      selectAvgResourceColumn();
      if (isProject.value) {
        router.push({ name: PROJECT_ROUTE_NAMES.PROJECT_INDEX });
      } else {
        router.push({ name: DEPARTMENT_ROUTE_NAMES.DEPARTMENT_INDEX });
      }
    };

    const selectAvgResourceColumn = () => {
      const tableFilterName = getTableFilterName();
      const filters = loadEntityFilters(tableFilterName);
      updateDisplayedColumns(filters);
      updateEntityFilters(filters);
      filterService.saveFilters(tableFilterName, filters);
    };

    const getTableFilterName = () => {
      return isProject.value ? ETableFilters.PROJECT : ETableFilters.DEPARTMENT;
    };

    const loadEntityFilters = (tableFilterName: ETableFilters) => {
      return filterService.loadFilters(window.location, tableFilterName, {}) as
        | IProjectTableFilterBy
        | IDepartmentTableFilterBy;
    };

    const updateDisplayedColumns = (filters: IProjectTableFilterBy | IDepartmentTableFilterBy) => {
      if (filters.displayedColumns?.length) {
        if (!filters.displayedColumns.includes(EOrgUnitOverTimeColumnName.AverageGpuUtilization)) {
          filters.displayedColumns.push(EOrgUnitOverTimeColumnName.AverageGpuUtilization);
        }
        if (!filters.displayedColumns.includes(EOrgUnitOverTimeColumnName.AverageGpuAllocation)) {
          filters.displayedColumns.push(EOrgUnitOverTimeColumnName.AverageGpuAllocation);
        }
      }
    };

    const updateEntityFilters = (filters: IProjectTableFilterBy | IDepartmentTableFilterBy) => {
      filters.avgGpuAllocationTimeframe = selectedTimeFrameOption.value.value;
      filters.descending = isTopAverageMetric.value;
      filters.sortBy = isAllocationMetric.value
        ? EOrgUnitOverTimeColumnName.AverageGpuAllocation
        : EOrgUnitOverTimeColumnName.AverageGpuUtilization;
    };

    const highlightColumn = () => {
      filterBy.value.sortBy = isAllocationMetric.value
        ? EOrgUnitRankingColumnName.AllocationValue
        : EOrgUnitRankingColumnName.UtilizationValue;

      filterBy.value.descending = isTopAverageMetric.value;
    };

    const columns = computed(() => {
      const entityColumns = isProject.value ? projectsRankingColumns : departmentsRankingColumns;
      const highlightColumnName = isAllocationMetric.value
        ? EOrgUnitRankingColumnName.AllocationValue
        : EOrgUnitRankingColumnName.UtilizationValue;

      return entityColumns.map((col) =>
        col.name === highlightColumnName ? { ...col, ...HIGHLIGHT_COLUMN_CONFIG, sortable: true } : col,
      );
    });

    const wrapperOptions = computed(() => {
      const linkText = isProject.value ? "All projects" : "All departments";
      const entityType = isProject.value ? EWidgetEntity.Project : EWidgetEntity.Department;
      return {
        title: "",
        linkText,
        entityType,
      };
    });

    const isProject = computed(() => selectedEntityOption.value.value === EOrgUnitEntityRankingValue.Project);
    const empty = computed(() => !loading.value && rankingRecords.value.length === 0);
    const emptyStateMessage = computed(() => (isProject.value ? "No projects to display" : "No departments to display"));
    const getEntityFunc = computed(() =>
      isProject.value ? orgUnitService.getProjectsRanking : orgUnitService.getDepartmentsRanking,
    );
    const getWorkloadCountFilters = (record: IOrgUnitRankingTable) => {
      const entityFilterName = isProject.value
        ? WorkloadSortFilterFields.ProjectName
        : WorkloadSortFilterFields.DepartmentName;
      return [
        filterUtil.getEqualsFilterString(entityFilterName, record.name),
        filterUtil.getEqualsFilterString(WorkloadSortFilterFields.ClusterId, props.clusterId),
      ];
    };

    const isAllocationMetric = computed(() =>
      [
        EOrgUnitRankingMetricValue.TopAverageGpuAllocation,
        EOrgUnitRankingMetricValue.BottomAverageGpuAllocation,
      ].includes(selectedMetricOption.value.value),
    );

    const isTopAverageMetric = computed(() =>
      [EOrgUnitRankingMetricValue.TopAverageGpuAllocation, EOrgUnitRankingMetricValue.TopAverageGpuUtilization].includes(
        selectedMetricOption.value.value,
      ),
    );

    watch(
      [
        selectedEntityOption,
        selectedTimeFrameOption,
        selectedMetricOption,
        () => props.nodePoolName,
        () => props.clusterId,
      ],
      async () => {
        loading.value = true;
        error.value = false;
        highlightColumn();
        await getEntityRanking();
        await enrichRecordsWithWorkloadsCount();
      },
    );

    useRefresh(EIntervalLabels.OrgUnitRankingWidget, async () => {
      await getEntityRanking();
      await enrichRecordsWithWorkloadsCount();
    });

    return {
      loading,
      error,
      empty,
      rankingRecords,
      filterBy,
      wrapperOptions,
      redirectToEntityIndex,
      entityOptions,
      selectedEntityOption,
      metricOptions,
      selectedMetricOption,
      timeFrameOptions,
      selectedTimeFrameOption,
      columns,
      emptyStateMessage,
    };
  },
});
</script>

<style scoped lang="scss">
.org-unit-ranking-widget {
  .header-wrapper {
    display: flex;
    height: 50px;
    .selects-wrapper {
      display: flex;
      gap: 20px;
    }
  }
}
</style>
