<template>
  <q-card>
    <div class="widgets-content-wrapper">
      <runai-datepicker-with-predefined
        aid="overtime-widget-date-picker"
        class="element-bottom-margin"
        @changed="updateDateRange"
        :range-of-dates="rangeOfDatesCallback"
        info-bar-message="Select a period of up to 30 days within the last 90 days"
        :predefined-options="predefinedOptions"
        :default-option-index="5"
        :default-end-date="filterByDates?.dateEnd"
        :default-start-date="filterByDates?.dateStart"
        :default-option-label="filterByDates?.label"
      />
      <resource-allocation-time-range-widget
        class="element-bottom-margin"
        flat
        :filter-by-dates="filterByDates"
        :cluster-id="clusterId"
      />
      <resource-utilization-time-range-widget flat :filter-by-dates="filterByDates" :cluster-id="clusterId" />
    </div>
  </q-card>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { RunaiDatepickerWithPredefined } from "@/components/common/runai-datepicker-with-predefined";
import { dateUtil, extendedHoursPredefinedOptions, type IRangeDates, TimeUnit } from "@/utils/date.util";
import type { PredefinedOption } from "@/models/date.model";
import { ResourceAllocationTimeRangeWidget } from "@/components/dashboard-v2/widgets/time-range-widgets/resource-allocation-time-range-widget";
import { ResourceUtilizationTimeRangeWidget } from "@/components/dashboard-v2/widgets/time-range-widgets/resource-utilization-time-range-widget";
export default defineComponent({
  name: "overview-time-range-widgets",
  components: { ResourceUtilizationTimeRangeWidget, ResourceAllocationTimeRangeWidget, RunaiDatepickerWithPredefined },
  emits: ["date-changed"],
  props: {
    filterByDates: {
      type: Object as PropType<IRangeDates>,
      required: false,
    },
    clusterId: {
      type: String as PropType<string>,
      required: true,
    },
    nodePoolName: {
      type: String as PropType<string>,
      required: false,
    },
  },
  computed: {
    predefinedOptions(): PredefinedOption[] {
      return extendedHoursPredefinedOptions;
    },
  },
  methods: {
    updateDateRange(dateRange: Array<Date>, label: string): void {
      this.$emit("date-changed", { startDate: dateRange[0], endDate: dateRange[1], label });
    },
    rangeOfDatesCallback(date: string): boolean {
      const todayDate: number = new Date().getTime();
      const dateToCheck: number = Date.parse(date);
      const back90Days: Date = dateUtil.adjustDateBy(TimeUnit.day, todayDate, -90);
      return dateToCheck >= back90Days.getTime() && dateToCheck <= todayDate;
    },
  },
});
</script>

<style scoped lang="scss">
.widgets-content-wrapper {
  padding: 12px 16px;
}
.element-bottom-margin {
  margin-bottom: 13px;
}
</style>
