<template>
  <hybrid-section-wrapper
    aid="hybrid-environment-section"
    label="Environment"
    :selected-name="selectedName"
    @load-section-templates="$emit('load-section-templates')"
    @clear="$emit('clear')"
    @reset="$emit('reset')"
    default-opened
  >
    <template #subheader>
      <span>{{ environmentSummary }}</span>
    </template>

    <form-image-input
      class="q-mb-md"
      :image="workloadSpec.image"
      :policy-rules="policy?.rules?.image"
      @update="updateModel('image', $event)"
    />

    <runai-folding-sub-section label="Image pull policy" aid="image-pull-policy-sub-section">
      <form-image-pull-policy
        :image-pull-policy="workloadSpec.imagePullPolicy"
        :policy-rules="policy?.rules?.imagePullPolicy"
        @update="updateModel('imagePullPolicy', $event)"
      />
    </runai-folding-sub-section>

    <runai-folding-sub-section label="Tools" aid="tools-section">
      <form-tools-list :tools="workloadSpec.connections" @update="updateTools" :cluster-id="clusterId" />
    </runai-folding-sub-section>

    <runai-folding-sub-section label="Runtime settings" aid="runtime-settings-sub-section">
      <form-runtime-settings
        :form-type="EEnvVarFormType.Hybrid"
        :render-key="renderKey"
        :runtime-settings="runtimeSettings"
        :environment-variable-source-value-options="environmentVariableSourceValueOptions"
        :policy="policy"
        :support-config-map-as-env-var="supportConfigMapAsEnvVar"
        @update="updateRuntimeSettings"
      />
    </runai-folding-sub-section>

    <runai-folding-sub-section label="Security" aid="security-sub-section">
      <form-security-settings
        :security-model="securityModel"
        :is-s-s-o="authStore.isSSO"
        :policy-rules="policy?.rules"
        @update="updateSecuritySettings"
      />
    </runai-folding-sub-section>
  </hybrid-section-wrapper>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Stores
import { useAuthStore } from "@/stores/auth.store";
// Cmps
import { HybridSectionWrapper } from "../hybrid-section-wrapper";
import { RunaiFoldingSubSection } from "@/components/common/runai-folding-sub-section";
import { FormImageInput } from "@/components/environment/form-image-input";
import { FormImagePullPolicy } from "@/components/environment/form-image-pull-policy";
import { FormRuntimeSettings } from "@/components/environment/form-runtime-section/form-runtime-settings";
import { FormSecuritySettings } from "@/components/environment/form-security-section/form-security-settings";
import { FormToolsList } from "@/components/environment/form-tools-section/form-tools-list";
// Models
import type { IUIWorkspaceSpec } from "@/models/workload.model";
import {
  EEnvVarFormType,
  type TEnvVarNoneCustomSourceOption,
  type IRuntimeSettings,
  type ISecurityModel,
  type IUIConnection,
} from "@/models/environment.model";
import type { UnifiedPolicyInfoPerReplicaType } from "@/swagger-models/workloads-client";

export default defineComponent({
  name: "hybrid-environment-section",
  components: {
    FormToolsList,
    FormImageInput,
    FormImagePullPolicy,
    FormSecuritySettings,
    FormRuntimeSettings,
    HybridSectionWrapper,
    RunaiFoldingSubSection,
  },
  emits: ["load-section-templates", "reset", "clear", "update"],
  props: {
    workloadSpec: {
      type: Object as PropType<IUIWorkspaceSpec>,
      required: true,
    },
    selectedName: {
      type: String as PropType<string>,
      required: false,
    },
    label: {
      type: String as PropType<string>,
      required: false,
    },
    environmentVariableSourceValueOptions: {
      type: Array as PropType<Array<TEnvVarNoneCustomSourceOption>>,
      required: false,
      default: () => [],
    },
    policy: {
      type: [Object, null] as PropType<UnifiedPolicyInfoPerReplicaType | null>,
      required: false,
    },
    clusterId: {
      type: String as PropType<string>,
      required: false,
    },
    renderKey: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
    supportConfigMapAsEnvVar: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      authStore: useAuthStore(),
      EEnvVarFormType,
    };
  },
  computed: {
    environmentSummary(): string {
      return this.workloadSpec.image || "";
    },
    runtimeSettings(): IRuntimeSettings {
      return {
        command: this.workloadSpec.command || null,
        args: this.workloadSpec.args || null,
        workingDir: this.workloadSpec.workingDir || null,
        hybridEnvironmentVariables: this.workloadSpec.environmentVariables || [],
      };
    },
    securityModel(): ISecurityModel {
      return {
        uidGidSource: this.workloadSpec.security?.uidGidSource || undefined,
        runAsUid: this.workloadSpec.security?.runAsUid,
        runAsGid: this.workloadSpec.security?.runAsGid,
        supplementalGroups: this.workloadSpec.security?.supplementalGroups,
        capabilities: this.workloadSpec.security?.capabilities,
      };
    },
  },
  methods: {
    updateModel(key: string, val: string | number | null | undefined) {
      this.$emit("update", { ...this.workloadSpec, [key]: val });
    },
    updateRuntimeSettings(runtimeSettings: IRuntimeSettings) {
      this.$emit("update", {
        ...this.workloadSpec,
        environmentVariables: runtimeSettings.hybridEnvironmentVariables,
        command: runtimeSettings.command,
        args: runtimeSettings.args,
        workingDir: runtimeSettings.workingDir,
      });
    },
    updateSecuritySettings(securityModel: ISecurityModel) {
      this.$emit("update", { ...this.workloadSpec, security: { ...this.workloadSpec.security, ...securityModel } });
    },
    updateTools(tools: IUIConnection[]) {
      this.$emit("update", { ...this.workloadSpec, connections: tools });
    },
  },
});
</script>
