<template>
  <template v-if="policyRules?.options?.length">
    <div class="row q-gutter-lg">
      <policy-string-field
        aid="claim-size-select"
        class="col-12"
        :model-value="modelValue"
        :disable="disable"
        :policy-rules="policyRules"
        stack-label
        label="Claim size"
        :rules="[isSelected, ...sizeFieldValidations()]"
        @update:model-value="updateSize"
      />
    </div>
  </template>
  <template v-else>
    <section class="volume-size-section">
      <section class="inputs-wrapper row">
        <q-input
          aid="claim-size-input"
          type="number"
          class="claim-size-input"
          :model-value="sizeNum"
          no-error-icon
          label="Claim size"
          min="0"
          stack-label
          @update:model-value="handleSizeChange(Number($event))"
          :disable="disable"
          :loading="loading"
          :error="showSizeError"
          hide-bottom-space
        />

        <runai-select
          aid="unit-select"
          class="claim-size-unit-select"
          standard
          :model-value="selectedUnit"
          label="Units"
          option-label="label"
          map-options
          emit-value
          :options="unitOptions"
          @update:model-value="handleUnitChange"
          :disable="disable"
          unclearable
          :error="showSizeError"
          :loading="loading"
          hide-bottom-space
        />
      </section>
      <q-field class="hidden-field q-field--with-bottom" :model-value="modelValue" :rules="sizeFieldValidations()" />
    </section>
  </template>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Components
import { RunaiSelect } from "@/components/common/runai-select";
import { PolicyStringField } from "@/components/common/policy-string-field";
// Constants
import { errorMessages } from "@/common/error-message.constant";
// Utils
import { isNotEmpty, isNumberGreaterThanZero } from "@/common/form.validators";
// Models
import { type ISelectOption, allMemoryUnitOptions } from "@/models/global.model";
import { separateDigitsAndLetters } from "@/utils/common.util";
import type { StringRules } from "@/swagger-models/policy-service-client";
import type {
  ClaimSizeCustomization,
  ClaimSizeCustomizationSupportedUnitsEnum,
} from "@/swagger-models/k8s-objects-tracker-client";
import { resourceUtil } from "@/utils/resource.util";

const DEFAULT_MIN_SIZE = "1M";

export default defineComponent({
  name: "volume-size-section",
  components: { RunaiSelect, PolicyStringField },
  emits: ["update:model-value"],
  props: {
    modelValue: {
      type: String as PropType<string>,
      required: true,
    },
    disable: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    policyRules: {
      type: [Object, null] as PropType<StringRules | null>,
      required: false,
      default: () => ({}),
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    claimSizeCustomization: {
      type: Object as PropType<ClaimSizeCustomization>,
      required: false,
    },
  },
  computed: {
    sizeNum(): number {
      const [sizeNum] = separateDigitsAndLetters(this.modelValue);
      return +sizeNum || 0;
    },
    selectedUnit(): string {
      const [, selectedUnit] = separateDigitsAndLetters(this.modelValue);
      return selectedUnit || "";
    },
    unitOptions(): Array<ISelectOption> {
      if (!this.claimSizeCustomization?.supportedUnits) return allMemoryUnitOptions;

      return allMemoryUnitOptions.map((option) => {
        if (
          this.claimSizeCustomization?.supportedUnits &&
          this.claimSizeCustomization.supportedUnits.includes(option.label as ClaimSizeCustomizationSupportedUnitsEnum)
        ) {
          return option;
        }
        return { ...option, disable: true, disabledTooltip: "This can't be selected based on the storage class setup" };
      });
    },
    minBytesSize(): number {
      return resourceUtil.parseK8sResourceSizeToBytes(this.claimSizeCustomization?.min || DEFAULT_MIN_SIZE);
    },
    maxBytesSize(): number {
      return resourceUtil.parseK8sResourceSizeToBytes(this.claimSizeCustomization?.max || "");
    },
    showSizeError(): boolean {
      const validationRules: Array<(x: string) => boolean | string> = this.sizeFieldValidations();
      return validationRules.some((rule) => rule(this.modelValue) !== true);
    },
  },
  methods: {
    handleSizeChange(val: number): void {
      this.updateSize(val + this.selectedUnit);
    },
    handleUnitChange(val: string): void {
      this.updateSize(this.sizeNum + val);
    },
    updateSize(size: string): void {
      this.$emit("update:model-value", size);
    },
    // Rules
    sizeNotEmpty(val: number): boolean | string {
      return (val !== undefined && !Number.isNaN(val) && val !== null) || errorMessages.ENTER_A_VALUE;
    },
    largerThanZero(val: number): boolean | string {
      return isNumberGreaterThanZero(val) || errorMessages.NUMBER_GREATER_THAN_ZERO;
    },
    isSelected(val: string): boolean | string {
      return isNotEmpty(val) || errorMessages.SELECT_A_VALUE;
    },
    sizeFieldValidations(): Array<(x: string) => string | boolean> {
      if (!this.claimSizeCustomization) return [this.isUnderMin];
      if (this.claimSizeCustomization.max) {
        return [this.isBetweenMinMax];
      }
      if (this.claimSizeCustomization.min) {
        return [this.isUnderMin];
      }
      return [];
    },
    isUnderMin(val: string): boolean | string {
      let valBytes = 0;
      try {
        valBytes = resourceUtil.parseK8sResourceSizeToBytes(val);
      } catch (err) {
        errorMessages.ENTER_A_VALUE_ABOVE_MIN.replace(
          "${min}",
          `${this.claimSizeCustomization?.min || DEFAULT_MIN_SIZE}B`,
        );
      }

      return (
        valBytes >= this.minBytesSize ||
        errorMessages.ENTER_A_VALUE_ABOVE_MIN.replace(
          "${min}",
          `${this.claimSizeCustomization?.min || DEFAULT_MIN_SIZE}B`,
        )
      );
    },
    isAboveMax(val: string): boolean | string {
      const valBytes = resourceUtil.parseK8sResourceSizeToBytes(val);
      return (
        valBytes <= this.maxBytesSize ||
        errorMessages.ENTER_A_VALUE_BELOW_MAX.replace("${max}", `${this.claimSizeCustomization?.max}B`)
      );
    },
    isBetweenMinMax(val: string): boolean | string {
      const valBytes = resourceUtil.parseK8sResourceSizeToBytes(val);
      return (
        (valBytes >= this.minBytesSize && valBytes <= this.maxBytesSize) ||
        errorMessages.ENTER_A_VALUE_BETWEEN.replace(
          "${min}",
          `${this.claimSizeCustomization?.min || DEFAULT_MIN_SIZE}B`,
        ).replace("${max}", `${this.claimSizeCustomization?.max}B`)
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.volume-size-section {
  .inputs-wrapper {
    gap: 30px;
  }
  .claim-size-input,
  .claim-size-unit-select {
    flex: 1;
  }
}
</style>
