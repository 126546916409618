// types
import {
  AssetKind,
  type CredentialsListResponseEntry,
  type PasswordCreationRequest,
  type AccessKeyCreationRequest,
  type AccessKey,
  type Password,
  type PasswordUpdateRequest,
  type AccessKeyUpdateRequest,
  type DockerRegistryCreationRequest,
  type DockerRegistry,
  type DockerRegistryUpdateRequest,
  type HttpResponse,
  type GenericSecretCreationRequest,
  type GenericSecret,
  type GenericSecretUpdateRequest,
} from "@/swagger-models/assets-service-client";
import type { SecretListInfo, SecretType } from "@/swagger-models/k8s-objects-tracker-client";
import type { TCredentialKinds } from "@/models/credential.model";
import { assetsServiceApi } from "@/services/infra/client-apis/assets-service-api/assets-service-api";
import { httpService } from "@/services/infra/https.service/http.service";

// constants
import type { IAssetsFilter } from "@/models/filter.model";

// services
import { pick } from "@/utils/common.util";
import { k8sObjectTrackerApi } from "@/services/infra/client-apis/k8s-object-tracker-api/k8s-object-tracker-api";

export const credentialService = {
  listCredentials,
  remove,
  getEmptyDockerRegistryModel,
  getEmptyUserpassModel,
  getEmptyAccessKeyModel,
  getEmptyGenericSecretCredentialModel,
  createDockerRegistry,
  createUserpass,
  createAccessKey,
  createGeneralCredential,
  updateUserpass,
  updateAccessKey,
  updateDockerRegistry,
  updateGenericSecret,
  listExistingSecrets,
  getCredentialById,
};

async function listCredentials(filterBy: IAssetsFilter): Promise<CredentialsListResponseEntry[]> {
  const filters: IAssetsFilter = pick(filterBy, "projectId", "scope", "usageInfo", "clusterUuid", "statusInfo");
  try {
    return assetsServiceApi.credentialApi
      .listCredentialsAssets(
        undefined,
        filters.scope,
        Number(filters.projectId) || undefined,
        filters.departmentId || undefined,
        filters.clusterUuid?.toString(),
        filters.usageInfo,
        filters.statusInfo,
      )
      .then((res) => res.data.entries);
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function listExistingSecrets(
  scopeType: string,
  scopeId: string,
  type?: SecretType,
  origin?: "all" | "assets" | "existing",
): Promise<SecretListInfo[]> {
  try {
    const response = await k8sObjectTrackerApi.secretsApi.listSecrets(
      scopeType,
      scopeId,
      undefined,
      type,
      undefined,
      origin,
      true,
    );
    return response.data.secrets;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function remove(credentialId: string, kind: TCredentialKinds): Promise<HttpResponse | void> {
  switch (kind) {
    case AssetKind.AccessKey:
      return removeAccessKey(credentialId);
    case AssetKind.DockerRegistry:
      return removeDockerRegistry(credentialId);
    case AssetKind.Password:
      return removeUserpass(credentialId);
    case AssetKind.GenericSecret:
      return removeGenericSecret(credentialId);
    default:
      throw `Wrong credential type: ${kind}`;
  }
}
interface ICredentialGetByIdParams {
  usageInfo?: boolean;
  statusInfo?: boolean;
}

async function getCredentialById(
  credentialId: string,
  kind: TCredentialKinds,
  options: ICredentialGetByIdParams = {},
): Promise<DockerRegistry | AccessKey | Password | GenericSecret> {
  switch (kind) {
    case AssetKind.AccessKey:
      return getAccesskeyById(credentialId, options);
    case AssetKind.DockerRegistry:
      return getDockerRegistryById(credentialId, options);
    case AssetKind.Password:
      return getUserpassById(credentialId, options);
    case AssetKind.GenericSecret:
      return getGenericSecretById(credentialId, options);
    default:
      throw `Wrong credential type: ${kind}`;
  }
}

async function getDockerRegistryById(
  dockerRegistryId: string,
  options: ICredentialGetByIdParams = {},
): Promise<DockerRegistry> {
  try {
    const params = pick(options, "usageInfo", "statusInfo");

    const response = await assetsServiceApi.credentialApi.getDockerRegistryById(
      dockerRegistryId,
      params.usageInfo,
      params.statusInfo,
    );
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}
async function getAccesskeyById(accesskeyId: string, options: ICredentialGetByIdParams = {}): Promise<AccessKey> {
  try {
    const params = pick(options, "usageInfo", "statusInfo");

    const response = await assetsServiceApi.credentialApi.getAccessKeyById(
      accesskeyId,
      params.usageInfo,
      params.statusInfo,
    );
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}
async function getUserpassById(userpassId: string, options: ICredentialGetByIdParams = {}): Promise<Password> {
  try {
    const params = pick(options, "usageInfo", "statusInfo");

    const response = await assetsServiceApi.credentialApi.getPasswordById(
      userpassId,
      params.usageInfo,
      params.statusInfo,
    );
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function getGenericSecretById(
  genericSecretId: string,
  options: ICredentialGetByIdParams = {},
): Promise<GenericSecret> {
  try {
    const params = pick(options, "usageInfo", "statusInfo");

    const response = await assetsServiceApi.credentialApi.getGenericSecretById(
      genericSecretId,
      params.usageInfo,
      params.statusInfo,
    );
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function removeDockerRegistry(dockerRegistryId: string): Promise<HttpResponse> {
  try {
    const response = await assetsServiceApi.credentialApi.deleteDockerRegistry(dockerRegistryId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function removeAccessKey(accesskeyId: string): Promise<HttpResponse> {
  try {
    const response = await assetsServiceApi.credentialApi.deleteAccessKey(accesskeyId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function removeUserpass(userpassId: string): Promise<HttpResponse> {
  try {
    const response = await assetsServiceApi.credentialApi.deletePassword(userpassId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function removeGenericSecret(genericSecretId: string): Promise<void> {
  try {
    const response = await assetsServiceApi.credentialApi.deleteGenericSecret(genericSecretId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function createDockerRegistry(dockerRegistry: DockerRegistryCreationRequest): Promise<DockerRegistry> {
  try {
    const response = await assetsServiceApi.credentialApi.createDockerRegistry(dockerRegistry);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function createUserpass(userpass: PasswordCreationRequest): Promise<Password> {
  try {
    const response = await assetsServiceApi.credentialApi.createPassword(userpass);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function createAccessKey(accessKey: AccessKeyCreationRequest): Promise<AccessKey> {
  try {
    const response = await assetsServiceApi.credentialApi.createAccessKey(accessKey);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function createGeneralCredential(generalCredential: GenericSecretCreationRequest): Promise<GenericSecret> {
  try {
    const response = await assetsServiceApi.credentialApi.createGenericSecret(generalCredential);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function updateUserpass(userpassId: string, userpass: PasswordUpdateRequest): Promise<Password> {
  try {
    const response = await assetsServiceApi.credentialApi.updatePassword(userpassId, userpass);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function updateAccessKey(accessKeyId: string, accessKey: AccessKeyUpdateRequest): Promise<AccessKey> {
  try {
    const response = await assetsServiceApi.credentialApi.updateAccessKey(accessKeyId, accessKey);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function updateDockerRegistry(
  dockerRegistryId: string,
  dockerRegistry: DockerRegistryUpdateRequest,
): Promise<DockerRegistry> {
  try {
    const response = await assetsServiceApi.credentialApi.updateDockerRegistry(dockerRegistryId, dockerRegistry);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function updateGenericSecret(
  genericSecretId: string,
  genericSecret: GenericSecretUpdateRequest,
): Promise<GenericSecret> {
  try {
    const response = await assetsServiceApi.credentialApi.updateGenericSecret(genericSecretId, genericSecret);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

function getEmptyDockerRegistryModel(): DockerRegistryCreationRequest {
  return {
    meta: {
      name: "",
      description: null,
      scope: null,
      projectId: null,
      clusterId: null,
    },
    spec: {
      existingSecretName: null,
      password: "",
      user: "",
      url: "",
    },
  };
}

function getEmptyUserpassModel(): PasswordCreationRequest {
  return {
    meta: {
      name: "",
      description: null,
      scope: null,
      projectId: null,
      clusterId: null,
    },
    spec: {
      existingSecretName: null,
      password: "",
      user: "",
    },
  };
}

function getEmptyAccessKeyModel(): AccessKeyCreationRequest {
  return {
    meta: {
      name: "",
      description: null,
      scope: null, // TODO: Figure out why it does not allow Null
      projectId: null,
      clusterId: null,
    },
    spec: {
      existingSecretName: null,
      accessKeyId: "",
      secretAccessKey: "",
    },
  };
}

function getEmptyGenericSecretCredentialModel(): GenericSecretCreationRequest {
  return {
    meta: {
      name: "",
      description: null,
      scope: null,
      projectId: null,
      clusterId: null,
    },
    spec: {
      existingSecretName: "",
    },
  };
}
