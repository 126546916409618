<template>
  <section class="form-image-pull-policy">
    <runai-section title="Set the condition for pulling the image">
      <runai-radio-options
        :options="imageRadioOptions"
        :model-value="imagePullPolicy || null"
        @update:model-value="$emit('update', $event)"
        :policy-rules="radioOptionsRules"
      />
    </runai-section>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Cmp
import { RunaiRadioOptions } from "@/components/common/runai-radio-options";
import { RunaiSection } from "@/components/common/runai-section";
// Types
import type { ISelectOption } from "@/models/global.model";
import { IMAGE_PULL_POLICY_LABELS } from "@/models/environment.model";
import { ImagePullPolicy } from "@/swagger-models/assets-service-client";
import type { ImagePullPolicyRules } from "@/swagger-models/workloads-client";
import type { IGenericRadioOptionsPolicyRules } from "@/models/policy.model";

export default defineComponent({
  name: "form-image-pool-policy",
  components: {
    RunaiRadioOptions,
    RunaiSection,
  },
  emits: ["update"],
  props: {
    imagePullPolicy: {
      type: [String, null] as PropType<ImagePullPolicy | null>,
      required: false,
    },
    policyRules: {
      type: [Object, null] as PropType<ImagePullPolicyRules | null>,
      required: false,
    },
  },
  data() {
    return {
      imageRadioOptions: [
        {
          label: `${IMAGE_PULL_POLICY_LABELS.IfNotPresent} (recommended)`,
          value: ImagePullPolicy.IfNotPresent,
        },
        { label: IMAGE_PULL_POLICY_LABELS.Always, value: ImagePullPolicy.Always },
        {
          label: `${IMAGE_PULL_POLICY_LABELS.Never} (if the image is already present on the host, it will be used)`,
          value: ImagePullPolicy.Never,
        },
      ] as ISelectOption[],
    };
  },
  computed: {
    radioOptionsRules(): IGenericRadioOptionsPolicyRules {
      return this.policyRules as IGenericRadioOptionsPolicyRules;
    },
  },
});
</script>
