import type { INodePoolsNameAndId } from "@/models/node-pool.model";
import type { ICustomHeaderRadioButtonsConfig } from "@/models/table.model";
import type { ISelectOption } from "@/models/global.model";
import { RankingMetricType, RankingOrderType } from "@/swagger-models/org-unit-service-client";

export interface IEmptyProjectModelConfig {
  nodePoolsNamesAndIds: INodePoolsNameAndId[];
  clusterId: string;
  isCpuEnabled: boolean;
  parentId?: string;
}

export interface IEmptyDepartmentModelConfig {
  nodePoolsNamesAndIds: INodePoolsNameAndId[];
  clusterId: string;
  isCpuEnabled: boolean;
}
/** Over time data **/
export enum EOrgUnitOverTimeValue {
  TwentyFourHours = "24h",
  SevenDays = "7d",
  ThirtyDays = "30d",
}

export const ORG_UNIT_OVERTIME_CUSTOM_HEADER_CONFIG = {
  label: "Show the last",
  options: [
    { label: "24 hours", value: EOrgUnitOverTimeValue.TwentyFourHours, shortLabel: "24h" },
    { label: "7 days", value: EOrgUnitOverTimeValue.SevenDays, shortLabel: "7d" },
    { label: "30 days", value: EOrgUnitOverTimeValue.ThirtyDays, shortLabel: "30d" },
  ],
  iconName: "fa-regular fa-calendar-clock q-mr-sm text-black-30",
  chipPrefix: "Last",
} as Partial<ICustomHeaderRadioButtonsConfig>;

export enum EOrgUnitOverTimeColumnName {
  AverageGpuUtilization = "averageGpuUtilization",
  AverageGpuAllocation = "averageGpuAllocation",
}

/** Ranking **/
export enum EOrgUnitEntityRankingValue {
  Department = "Department",
  Project = "Project",
}

export enum EOrgUnitRankingMetricValue {
  TopAverageGpuAllocation = "topAverageGpuAllocation",
  TopAverageGpuUtilization = "topAverageGpuUtilization",
  BottomAverageGpuAllocation = "bottomAverageGpuAllocation",
  BottomAverageGpuUtilization = "bottomAverageGpuUtilization",
}

export interface IOrgUnitEntitySelectOption extends ISelectOption {
  value: EOrgUnitEntityRankingValue;
}

export const ORG_UNIT_RANKING_ENTITY_SELECT_OPTIONS: IOrgUnitEntitySelectOption[] = [
  { label: "Projects", value: EOrgUnitEntityRankingValue.Project },
  { label: "Departments", value: EOrgUnitEntityRankingValue.Department },
];

export interface IOrgUnitRankingMetricSelectOption extends ISelectOption {
  value: EOrgUnitRankingMetricValue;
}

export const ORG_UNIT_RANKING_METRIC_SELECT_OPTIONS: IOrgUnitRankingMetricSelectOption[] = [
  { label: "Highest Avg. GPU allocation", value: EOrgUnitRankingMetricValue.TopAverageGpuAllocation },
  { label: "Lowest Avg. GPU allocation", value: EOrgUnitRankingMetricValue.BottomAverageGpuAllocation },
  { label: "Highest  Avg. GPU utilization", value: EOrgUnitRankingMetricValue.TopAverageGpuUtilization },
  { label: "Lowest  Avg. GPU utilization", value: EOrgUnitRankingMetricValue.BottomAverageGpuUtilization },
];

export interface IOrgUnitTimeFrameSelectOption extends ISelectOption {
  value: EOrgUnitOverTimeValue;
}

export const ORG_UNIT_TIME_FRAME_SELECT_OPTIONS: IOrgUnitTimeFrameSelectOption[] = [
  { label: "Last 24h", value: EOrgUnitOverTimeValue.TwentyFourHours },
  { label: "Last 7d", value: EOrgUnitOverTimeValue.SevenDays },
  { label: "Last 30d", value: EOrgUnitOverTimeValue.ThirtyDays },
];

export const rankingMetricTypeMap: Record<
  EOrgUnitRankingMetricValue,
  Record<EOrgUnitOverTimeValue, RankingMetricType>
> = {
  [EOrgUnitRankingMetricValue.TopAverageGpuAllocation]: {
    [EOrgUnitOverTimeValue.TwentyFourHours]: RankingMetricType.AvgGpuAllocation24h,
    [EOrgUnitOverTimeValue.SevenDays]: RankingMetricType.AvgGpuAllocation7d,
    [EOrgUnitOverTimeValue.ThirtyDays]: RankingMetricType.AvgGpuAllocation30d,
  },
  [EOrgUnitRankingMetricValue.TopAverageGpuUtilization]: {
    [EOrgUnitOverTimeValue.TwentyFourHours]: RankingMetricType.AvgGpuUtilization24h,
    [EOrgUnitOverTimeValue.SevenDays]: RankingMetricType.AvgGpuUtilization7d,
    [EOrgUnitOverTimeValue.ThirtyDays]: RankingMetricType.AvgGpuUtilization30d,
  },
  [EOrgUnitRankingMetricValue.BottomAverageGpuAllocation]: {
    [EOrgUnitOverTimeValue.TwentyFourHours]: RankingMetricType.AvgGpuAllocation24h,
    [EOrgUnitOverTimeValue.SevenDays]: RankingMetricType.AvgGpuAllocation7d,
    [EOrgUnitOverTimeValue.ThirtyDays]: RankingMetricType.AvgGpuAllocation30d,
  },
  [EOrgUnitRankingMetricValue.BottomAverageGpuUtilization]: {
    [EOrgUnitOverTimeValue.TwentyFourHours]: RankingMetricType.AvgGpuUtilization24h,
    [EOrgUnitOverTimeValue.SevenDays]: RankingMetricType.AvgGpuUtilization7d,
    [EOrgUnitOverTimeValue.ThirtyDays]: RankingMetricType.AvgGpuUtilization30d,
  },
};

export const rankingOrderMap: Record<EOrgUnitRankingMetricValue, RankingOrderType> = {
  [EOrgUnitRankingMetricValue.TopAverageGpuAllocation]: RankingOrderType.Top,
  [EOrgUnitRankingMetricValue.TopAverageGpuUtilization]: RankingOrderType.Top,
  [EOrgUnitRankingMetricValue.BottomAverageGpuAllocation]: RankingOrderType.Bottom,
  [EOrgUnitRankingMetricValue.BottomAverageGpuUtilization]: RankingOrderType.Bottom,
};
