export enum ENimAccessModelMethod {
  Manually = "manually",
  FromSecret = "from_secret",
}

export interface INimAccessModelSelectorModel {
  accessFromStorage: boolean;
  accessModelMethod?: ENimAccessModelMethod;
  apiKey?: string;
  selectedCredentials?: string;
}

export enum ENimAccessModelSelectorEvents {
  UpdateModelValue = "update:model-value",
  CreateNewCredentials = "create-new-credentials",
}
