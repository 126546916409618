import type { RankingMetricType, RankingOrderType } from "@/swagger-models/org-unit-service-client";
import {
  type Department,
  type DepartmentCreationRequest,
  DepartmentFilterSortFields,
  type DepartmentUpdateRequest,
  type OvertimeData,
  type Project,
  type ProjectCreationRequest,
  ProjectFilterSortFields,
  ProjectPhase,
  type ProjectStatus,
  type ProjectUpdateRequest,
  type Resources,
  type ResourcesCpu,
  type ResourcesGpu,
  type ResourcesMemory,
  type ResourcesNodePool,
  type SchedulingRules,
  type TotalResources,
  Units,
} from "@/swagger-models/org-unit-service-client";
import {
  EOverQuotaPriority,
  OVER_QUOTA_DISABLED_VALUE,
  OVER_QUOTA_ENABLED_VALUE,
  RESOURCE_MAX_ALLOWED_INFINITE_VALUE,
} from "@/models/resource.model";
import type { INodePoolsNameAndId } from "@/models/node-pool.model";
import type { IProgressColOptions, IStatusColOptions } from "@/models/table.model";
import {
  EProjectConditionDeletionReason,
  type IProjectTable,
  type IProjectTableFilterBy,
  ProjectPhaseMap,
} from "@/models/project.model";
import { tableUtil } from "@/utils/table.util";
import { EOrgUnitRankingMetricValue } from "@/models/org-unit.model";
import {
  EOrgUnitOverTimeColumnName,
  EOrgUnitOverTimeValue,
  type IEmptyDepartmentModelConfig,
  type IEmptyProjectModelConfig,
  rankingMetricTypeMap,
  rankingOrderMap,
} from "@/models/org-unit.model";
import { toPercent } from "@/utils/format.util";
import { CLUSTER_COLUMN_FILTER_NAME, type IPaginationFilter } from "@/models/filter.model";
import { filterService } from "@/services/filter.service/filter.service";
import type { IDepartmentTableFilterBy } from "@/models/department.model";

export const orgUnitUtil = {
  getEmptyProjectModel,
  getEmptyResourcesModel,
  getEmptyDepartmentModel,
  getStatusColOptions,
  getNodePoolNameByResource,
  getNodePoolIdByResource,
  removeOverQuotaWeightFields,
  getProjectUpdateRequest,
  getDepartmentUpdateRequest,
  updateLimitOrOverQuota,
  enrichResourcesWithCpuAndMemory,
  initResourcesOverQuotaWeight,
  updateSchedulingRules,
  initSchedulingRules,
  updateOverQuotaWeightToDeserved,
  getAverageGpuAllocationDataByTimeframe,
  getAverageGpuUtilizationDataByTimeframe,
  getProjectListFilters,
  getDepartmentListFilters,
  getAllocationRatioDisplayValue,
  mapRankingMetricToMetricTypeAndOrder,
  getProgressColOptions,
  getAvgResourceFieldName,
};

function getEmptyProjectModel(config: IEmptyProjectModelConfig): ProjectCreationRequest {
  const emptyResources: Resources[] = getEmptyResourcesModel(config.nodePoolsNamesAndIds, config.isCpuEnabled);
  return {
    clusterId: config.clusterId,
    parentId: config.parentId,
    resources: emptyResources,
    name: "",
    description: "",
    requestedNamespace: "",
  };
}

function getEmptyDepartmentModel(config: IEmptyDepartmentModelConfig): DepartmentCreationRequest {
  const emptyResources: Resources[] = getEmptyResourcesModel(config.nodePoolsNamesAndIds, config.isCpuEnabled);
  return {
    clusterId: config.clusterId,
    resources: emptyResources,
    name: "",
    description: "",
  };
}

function getEmptyResourcesCpu(isCpuEnabled: boolean): ResourcesCpu | null {
  return isCpuEnabled
    ? {
        deserved: null,
        limit: RESOURCE_MAX_ALLOWED_INFINITE_VALUE,
        overQuotaWeight: OVER_QUOTA_ENABLED_VALUE,
      }
    : null;
}
function getEmptyResourcesMemory(isCpuEnabled: boolean): ResourcesMemory | null {
  return isCpuEnabled
    ? {
        deserved: null,
        limit: RESOURCE_MAX_ALLOWED_INFINITE_VALUE,
        overQuotaWeight: OVER_QUOTA_ENABLED_VALUE,
        units: Units.Mib,
      }
    : null;
}
function getEmptyResourcesModel(nodePools: INodePoolsNameAndId[], isCpuEnabled = false): Resources[] {
  return nodePools.map((nodePool) => {
    return {
      nodePool: {
        name: nodePool.name,
        id: nodePool.id,
      } as ResourcesNodePool,
      gpu: {
        deserved: 0,
        limit: RESOURCE_MAX_ALLOWED_INFINITE_VALUE,
        overQuotaWeight: OVER_QUOTA_ENABLED_VALUE,
      } as ResourcesGpu,
      cpu: getEmptyResourcesCpu(isCpuEnabled),
      memory: getEmptyResourcesMemory(isCpuEnabled),
    };
  });
}

function enrichResourcesWithCpuAndMemory(resources: Resources[]): Resources[] {
  return resources.map((resource) => {
    if (!resource.cpu) {
      resource.cpu = getEmptyResourcesCpu(true);
    }
    if (!resource.memory) {
      resource.memory = getEmptyResourcesMemory(true);
    }

    return resource;
  });
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
function _mapProjectStatusConditionsToMessages(conditions: any[]): string[] {
  return conditions
    .filter(
      (item) =>
        item.status === "False" && item.message && Object.keys(EProjectConditionDeletionReason).includes(item.reason),
    )
    .map((item) => item.message);
}

function _mapMessagesToTooltipText(messages: string[]): string {
  if (messages.length === 0) return "";
  const messagesList = messages
    .map((message) => message)
    .join("")
    .replace(/(?:\r\n|\r|\n)/g, "<br/>")
    .replace("first:", "first:<br>");

  return `
 ${messagesList}<br>
 Either delete the above or try <a href="javascript:void(0)" style="text-decoration: underline;" id="status-col-emit-project-deletion">deleting the project anyway</a>`;
}

function _mapDeletingMessagesToTooltipText(status: ProjectStatus): string {
  const messages = _mapProjectStatusConditionsToMessages(status?.additionalStatusData?.conditions || []);
  return _mapMessagesToTooltipText(messages);
}

function getStatusColOptions(project?: IProjectTable): IStatusColOptions {
  if (!project?.status || !project?.status.phase)
    return {
      status: "-",
      tooltipText: "",
      displayAnimation: false,
    };
  const statusOptions: IStatusColOptions = ProjectPhaseMap[project.status.phase];
  const options = tableUtil.getStatusColOptions(statusOptions, project.status.phaseMessage || "");
  let tooltipText = "";
  if (project.status.phase === ProjectPhase.Deleting) {
    tooltipText = _mapDeletingMessagesToTooltipText(project.status);
  }

  if (tooltipText) {
    options.tooltipText = tooltipText;
  }
  return options;
}

function getNodePoolNameByResource(resources: Resources): string {
  return resources?.nodePool?.name || "";
}

function getNodePoolIdByResource(resources: Resources): string {
  return resources?.nodePool?.id || "";
}

function removeOverQuotaWeightFields(resources: Resources[]): Resources[] {
  return resources.map((resource) => {
    return {
      ...resource,
      gpu: {
        ...resource.gpu,
        overQuotaWeight: undefined,
      },
      cpu: resource.cpu
        ? {
            ...resource.cpu,
            overQuotaWeight: undefined,
          }
        : undefined,
      memory: resource.memory
        ? {
            ...resource.memory,
            overQuotaWeight: undefined,
          }
        : undefined,
    };
  });
}

function getProjectUpdateRequest(project: Project): ProjectUpdateRequest {
  return {
    description: project.description,
    schedulingRules: project.schedulingRules,
    defaultNodePools: project.defaultNodePools,
    nodeTypes: project.nodeTypes,
    resources: project.resources,
  };
}

function getDepartmentUpdateRequest(department: Department): DepartmentUpdateRequest {
  return {
    description: department.description,
    resources: department.resources,
    schedulingRules: department.schedulingRules,
    nodeTypes: department.nodeTypes,
    defaultNodePools: department.defaultNodePools,
  };
}

/**** limit and over quota weight update functions ****/

/**
 * Updates the overQuotaWeight of resources based on their limit.
 *
 * @param {Resources[]} resources - The array of resources to be updated.
 * @returns {Resources[]} - The updated array of resources.
 * more info here - https://runai.atlassian.net/browse/RUN-18379
 */
function updateOverQuotaWeightByLimit(resources: Resources[]): Resources[] {
  return resources.map((resource: Resources) => {
    const updatedResource = { ...resource };
    if (updatedResource.gpu.overQuotaWeight === EOverQuotaPriority.Lowest) {
      updatedResource.gpu.overQuotaWeight = EOverQuotaPriority.None;
      updatedResource.gpu.limit = RESOURCE_MAX_ALLOWED_INFINITE_VALUE;
      //CPU FF is enabled
      if (updatedResource?.cpu && updatedResource?.memory) {
        updatedResource.cpu.overQuotaWeight = EOverQuotaPriority.None;
        updatedResource.memory.overQuotaWeight = EOverQuotaPriority.None;
        updatedResource.cpu.limit = RESOURCE_MAX_ALLOWED_INFINITE_VALUE;
        updatedResource.memory.limit = RESOURCE_MAX_ALLOWED_INFINITE_VALUE;
      }
    } else if (updatedResource.gpu.overQuotaWeight === EOverQuotaPriority.None) {
      updatedResource.gpu.limit = updatedResource.gpu.deserved;
      updatedResource.gpu.overQuotaWeight = EOverQuotaPriority.None;

      if (updatedResource?.cpu && updatedResource?.memory) {
        updatedResource.cpu.overQuotaWeight = EOverQuotaPriority.None;
        updatedResource.memory.overQuotaWeight = EOverQuotaPriority.None;
        updatedResource.cpu.limit = updatedResource.cpu.deserved;
        updatedResource.memory.limit = updatedResource.memory.deserved;
      }
    } else {
      // over quota is enabled
      updatedResource.gpu.limit = RESOURCE_MAX_ALLOWED_INFINITE_VALUE;
      if (updatedResource?.cpu && updatedResource?.memory) {
        updatedResource.cpu.limit = RESOURCE_MAX_ALLOWED_INFINITE_VALUE;
        updatedResource.memory.limit = RESOURCE_MAX_ALLOWED_INFINITE_VALUE;
      }
    }
    return updatedResource;
  });
}

function initOverQuotaWeightByLimitOqEnabled(resources: Resources[]): Resources[] {
  return resources.map((resource: Resources) => {
    const updatedResource = { ...resource };
    if (updatedResource.gpu.limit === updatedResource.gpu.deserved) {
      updatedResource.gpu.overQuotaWeight = EOverQuotaPriority.None;
      if (updatedResource?.cpu && updatedResource?.memory) {
        updatedResource.cpu.overQuotaWeight = EOverQuotaPriority.None;
        updatedResource.memory.overQuotaWeight = EOverQuotaPriority.None;
      }
    } else if (
      updatedResource.gpu.limit === RESOURCE_MAX_ALLOWED_INFINITE_VALUE &&
      updatedResource.gpu.overQuotaWeight === EOverQuotaPriority.None
    ) {
      updatedResource.gpu.overQuotaWeight = EOverQuotaPriority.Lowest;
      if (updatedResource?.cpu && updatedResource?.memory) {
        updatedResource.cpu.overQuotaWeight = EOverQuotaPriority.Lowest;
        updatedResource.memory.overQuotaWeight = EOverQuotaPriority.Lowest;
      }
    }

    return updatedResource;
  });
}

/**
 * Updates the limit or deserved values of resources based on the over quota status.
 *
 * @param {Resources[]} resources - The array of resources to be updated.
 * @returns {Resources[]} - The updated array of resources.
 */
function updateLimitOrDeservedByOverQuota(resources: Resources[]): Resources[] {
  return resources.map((resource) => {
    const updatedResource = { ...resource };
    const isOverQuotaEnabled = updatedResource.gpu.overQuotaWeight === OVER_QUOTA_ENABLED_VALUE;

    /**
     * Updates the limit values to RESOURCE_MAX_ALLOWED_INFINITE_VALUE.
     */
    const updateLimitToOverQuota = () => {
      updatedResource.gpu.limit = RESOURCE_MAX_ALLOWED_INFINITE_VALUE;

      //CPU FF is enabled
      if (updatedResource?.cpu && updatedResource?.memory) {
        updatedResource.cpu.limit = RESOURCE_MAX_ALLOWED_INFINITE_VALUE;
        updatedResource.memory.limit = RESOURCE_MAX_ALLOWED_INFINITE_VALUE;
      }
    };

    /**
     * Updates the limit values to the deserved values.
     */
    const updateLimitToDeserved = () => {
      updatedResource.gpu.limit = updatedResource.gpu.deserved;

      //CPU FF is enabled
      if (updatedResource?.cpu && updatedResource?.memory) {
        updatedResource.cpu.limit = updatedResource.cpu?.deserved;
        updatedResource.memory.limit = updatedResource.memory?.deserved;
      }
    };

    if (isOverQuotaEnabled) {
      updateLimitToOverQuota();
    } else {
      updateLimitToDeserved();
    }
    return resource;
  });
}

/**
 * we have two states in our app, over quota weight feature is disable (binary choice enabled/disabled) and over quota weight feature is enabled.
 * for the first state, we have two options for overQuotaWeight, OVER_QUOTA_ENABLED_VALUE and OVER_QUOTA_DISABLED_VALUE, and we update the overQuotaWeight based on the limit, on load, and  update limit or deserved values on submit.
 * for the second state, we have multiple options for overQuotaWeight, (None, Lowest, Low, Medium, High) none means no over quota so limit = deserved.
 * @param resources
 * @param isOverQuotaPriorityEnabled
 */
function updateLimitOrOverQuota(resources: Resources[], isOverQuotaPriorityEnabled: boolean): Resources[] {
  if (isOverQuotaPriorityEnabled) {
    return updateOverQuotaWeightByLimit(resources);
  } else {
    return updateLimitOrDeservedByOverQuota(resources);
  }
}

function initOverQuotaWeightByLimit(resources: Resources[]): Resources[] {
  return resources.map((nodePool: Resources) => {
    const updatedNodePool = { ...nodePool };
    if (nodePool.gpu.limit === RESOURCE_MAX_ALLOWED_INFINITE_VALUE) {
      updatedNodePool.gpu.overQuotaWeight = OVER_QUOTA_ENABLED_VALUE;
    } else {
      updatedNodePool.gpu.overQuotaWeight = OVER_QUOTA_DISABLED_VALUE;
    }

    return updatedNodePool;
  });
}

// init the over overQuotaWeight based on limit
function initResourcesOverQuotaWeight(resources: Resources[], isOverQuotaPriorityEnabled: boolean): Resources[] {
  if (isOverQuotaPriorityEnabled) {
    return initOverQuotaWeightByLimitOqEnabled(resources);
  } else {
    return initOverQuotaWeightByLimit(resources);
  }
}

/***** SchedulingRules *****/

/**
 * Updates the project scheduling rules based on the parent scheduling rules.
 *
 * The project scheduling rules can only restrict (i.e., have a smaller value than) the parent scheduling rules.
 * If the project did not mutate the parent value, the project value is set to `null`.
 * If the parent value is `null` or undefined, the project value remains unchanged.
 * If the project value is greater than the parent value, the project value is set to the parent value. (this case should not happen due to validation).
 *
 * @param {SchedulingRules} projectSR - The scheduling rules of the project.
 * @param {SchedulingRules} parentSR - The scheduling rules of the parent.
 * @returns {SchedulingRules} - The updated scheduling rules for the project.
 */
function updateSchedulingRules(projectSR: SchedulingRules, parentSR: SchedulingRules): SchedulingRules {
  const updatedSR: SchedulingRules = { ...projectSR };

  for (const key in projectSR) {
    if (Object.prototype.hasOwnProperty.call(projectSR, key) && Object.prototype.hasOwnProperty.call(parentSR, key)) {
      const projectValue = projectSR[key as keyof SchedulingRules];
      const parentValue = parentSR[key as keyof SchedulingRules];

      if (parentValue !== null && parentValue !== undefined && projectValue !== null && projectValue !== undefined) {
        if (projectValue === parentValue) {
          updatedSR[key as keyof SchedulingRules] = null;
        }

        if (projectValue > parentValue) {
          updatedSR[key as keyof SchedulingRules] = parentValue;
        }
      }
    }
  }

  return updatedSR;
}

/**
 * Initializes the scheduling rules for a project based on the parent scheduling rules.
 *
 * This function will copy any scheduling rules from the parent that are not defined in the project.
 *
 * @param {SchedulingRules} projectSR - The scheduling rules of the project.
 * @param {SchedulingRules} parentSR - The scheduling rules of the parent.
 * @returns {SchedulingRules} - The initialized scheduling rules for the project.
 */
function initSchedulingRules(projectSR: SchedulingRules, parentSR: SchedulingRules): SchedulingRules {
  const updatedSR: SchedulingRules = { ...projectSR };
  for (const key in parentSR) {
    if (Object.prototype.hasOwnProperty.call(parentSR, key) && !Object.prototype.hasOwnProperty.call(projectSR, key)) {
      updatedSR[key as keyof SchedulingRules] = parentSR[key as keyof SchedulingRules];
    }
  }
  return updatedSR;
}

function updateOverQuotaWeightToDeserved(resources: Resources[]): Resources[] {
  return resources.map((resource) => {
    const updatedResource = { ...resource };
    updatedResource.gpu.overQuotaWeight = updatedResource.gpu.deserved;

    if (updatedResource?.cpu) {
      updatedResource.cpu.overQuotaWeight = updatedResource.cpu.deserved;
    }
    if (updatedResource?.memory) {
      updatedResource.memory.overQuotaWeight = updatedResource.memory.deserved;
    }

    return updatedResource;
  });
}

/** Over time data **/
function getAverageGpuAllocationDataByTimeframe(overtimeData: OvertimeData, timeframe: string): number | undefined {
  switch (timeframe) {
    case EOrgUnitOverTimeValue.TwentyFourHours:
      return overtimeData?.range24hData?.averageGpuAllocation;
    case EOrgUnitOverTimeValue.SevenDays:
      return overtimeData?.range7dData?.averageGpuAllocation;
    case EOrgUnitOverTimeValue.ThirtyDays:
      return overtimeData?.range30dData?.averageGpuAllocation;
    default:
      return undefined;
  }
}

function getAverageGpuUtilizationDataByTimeframe(overtimeData: OvertimeData, timeframe: string): number | undefined {
  switch (timeframe) {
    case EOrgUnitOverTimeValue.TwentyFourHours:
      return overtimeData?.range24hData?.averageGpuUtilization;
    case EOrgUnitOverTimeValue.SevenDays:
      return overtimeData?.range7dData?.averageGpuUtilization;
    case EOrgUnitOverTimeValue.ThirtyDays:
      return overtimeData?.range30dData?.averageGpuUtilization;
    default:
      return undefined;
  }
}

function _getAvgAllocationFilterKeyByTimeframe(timeframe: string): ProjectFilterSortFields {
  switch (timeframe) {
    case EOrgUnitOverTimeValue.TwentyFourHours:
      return ProjectFilterSortFields.AvgGpuAllocation24h;
    case EOrgUnitOverTimeValue.SevenDays:
      return ProjectFilterSortFields.AvgGpuAllocation7d;
    case EOrgUnitOverTimeValue.ThirtyDays:
      return ProjectFilterSortFields.AvgGpuAllocation30d;
    default:
      return ProjectFilterSortFields.AvgGpuAllocation24h;
  }
}

function _getAvgUtilizationFilterKeyByTimeframe(timeframe: string): ProjectFilterSortFields {
  switch (timeframe) {
    case EOrgUnitOverTimeValue.TwentyFourHours:
      return ProjectFilterSortFields.AvgGpuUtilization24h;
    case EOrgUnitOverTimeValue.SevenDays:
      return ProjectFilterSortFields.AvgGpuUtilization7d;
    case EOrgUnitOverTimeValue.ThirtyDays:
      return ProjectFilterSortFields.AvgGpuUtilization30d;
    default:
      return ProjectFilterSortFields.AvgGpuUtilization24h;
  }
}

function _replaceOverTimeFilterKey(
  filters: IPaginationFilter,
  allocationTimeframe: string,
  utilizationTimeframe: string,
): IPaginationFilter {
  const avgGpuAllocationKey = _getAvgAllocationFilterKeyByTimeframe(allocationTimeframe);
  const avgGpuUtilizationKey = _getAvgUtilizationFilterKeyByTimeframe(utilizationTimeframe);

  const sortBy = _replaceSortByKey(filters.sortBy, avgGpuAllocationKey, avgGpuUtilizationKey);
  const filterBy = _replaceFilterByKeys(filters.filterBy, avgGpuAllocationKey, avgGpuUtilizationKey);

  return {
    ...filters,
    sortBy,
    filterBy,
  };
}

function _replaceSortByKey(
  sortBy: string | undefined,
  avgGpuAllocationKey: string,
  avgGpuUtilizationKey: string,
): string | undefined {
  if (sortBy === EOrgUnitOverTimeColumnName.AverageGpuAllocation) {
    return avgGpuAllocationKey;
  }
  if (sortBy === EOrgUnitOverTimeColumnName.AverageGpuUtilization) {
    return avgGpuUtilizationKey;
  }
  return sortBy;
}

function _replaceFilterByKeys(
  filterBy: string[] | undefined,
  avgGpuAllocationKey: string,
  avgGpuUtilizationKey: string,
): string[] | undefined {
  if (!filterBy) return filterBy;

  return filterBy.map((filter) => {
    if (filter.startsWith(EOrgUnitOverTimeColumnName.AverageGpuAllocation)) {
      return filter.replace(EOrgUnitOverTimeColumnName.AverageGpuAllocation, avgGpuAllocationKey);
    }
    if (filter.startsWith(EOrgUnitOverTimeColumnName.AverageGpuUtilization)) {
      return filter.replace(EOrgUnitOverTimeColumnName.AverageGpuUtilization, avgGpuUtilizationKey);
    }
    return filter;
  });
}

function getProjectListFilters(filterBy: IProjectTableFilterBy): IPaginationFilter {
  const validFilterFields = [
    ...Object.values(ProjectFilterSortFields),
    CLUSTER_COLUMN_FILTER_NAME,
    EOrgUnitOverTimeColumnName.AverageGpuAllocation,
    EOrgUnitOverTimeColumnName.AverageGpuUtilization,
  ];
  const filters: IPaginationFilter = filterService.mapColumnsFilterToFilterParams(filterBy, validFilterFields, true);
  const gpuAllocationTimeframe = (filterBy as IProjectTableFilterBy).avgGpuAllocationTimeframe;
  const gpuUtilizationTimeframe = (filterBy as IProjectTableFilterBy).avgGpuUtilizationTimeframe;

  return _replaceOverTimeFilterKey(filters, gpuAllocationTimeframe, gpuUtilizationTimeframe);
}

function getDepartmentListFilters(filterBy: IDepartmentTableFilterBy): IPaginationFilter {
  const validFilterFields = [
    ...Object.values(DepartmentFilterSortFields),
    CLUSTER_COLUMN_FILTER_NAME,
    EOrgUnitOverTimeColumnName.AverageGpuAllocation,
    EOrgUnitOverTimeColumnName.AverageGpuUtilization,
  ];
  const filters: IPaginationFilter = filterService.mapColumnsFilterToFilterParams(filterBy, validFilterFields);
  const gpuAllocationTimeframe = (filterBy as IDepartmentTableFilterBy).avgGpuAllocationTimeframe;
  const gpuUtilizationTimeframe = (filterBy as IDepartmentTableFilterBy).avgGpuUtilizationTimeframe;

  return _replaceOverTimeFilterKey(filters, gpuAllocationTimeframe, gpuUtilizationTimeframe);
}

/** Tables utils **/

function getAllocationRatioDisplayValue(allocatedGpus: number, totalResources?: TotalResources): string {
  const gpuQuota = totalResources?.gpuQuota || 0;
  if (gpuQuota < 0) return "-";
  return toPercent(allocatedGpus || 0, gpuQuota);
}

/** Ranking utils **/

function mapRankingMetricToMetricTypeAndOrder(
  metricValue: EOrgUnitRankingMetricValue,
  timeFrame: EOrgUnitOverTimeValue,
): { metricType: RankingMetricType; order: RankingOrderType } {
  const metricType = rankingMetricTypeMap[metricValue]?.[timeFrame];
  const order = rankingOrderMap[metricValue];

  if (!metricType || !order) {
    throw new Error("Invalid metric value or time frame");
  }

  return { metricType, order };
}

function getProgressColOptions(value: number | null): IProgressColOptions {
  return {
    value: value,
    color: "soft-blue",
  };
}

function getAvgResourceFieldName(metricType: EOrgUnitRankingMetricValue, timeFrame: EOrgUnitOverTimeValue): string {
  switch (metricType) {
    case EOrgUnitRankingMetricValue.TopAverageGpuAllocation:
      return `avgGpuAllocation${timeFrame}`;
    case EOrgUnitRankingMetricValue.TopAverageGpuUtilization:
      return `avgGpuUtilization${timeFrame}`;
    case EOrgUnitRankingMetricValue.BottomAverageGpuAllocation:
      return `avgGpuAllocation${timeFrame}`;
    case EOrgUnitRankingMetricValue.BottomAverageGpuUtilization:
      return `avgGpuUtilization${timeFrame}`;
    default:
      return "";
  }
}
