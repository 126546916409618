<template>
  <section class="model-spec-index">
    <div class="q-pa-md row items-start q-gutter-md">
      <template v-if="loading">
        <model-spec-card-skeleton v-for="index in 3" :key="index" />
      </template>
      <template v-else>
        <model-spec-card
          v-for="model in filteredModelAssets"
          :key="model.meta.id"
          :model-spec="model"
          :disable-deploy="shouldDisableDeployBtn"
          :disable-deploy-reason="disableDeployBtnReason"
        />
      </template>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// Stores
import { useAppStore } from "@/stores/app.store";
import { usePermissionStore } from "@/stores/permissions.store";

// cmps
import { ModelSpecCard, ModelSpecCardSkeleton } from "@/components/model-spec/model-spec-card";

// models
import type { ModelAsset } from "@/swagger-models/assets-service-client";
import { Action, ResourceType } from "@/swagger-models/authorization-client";

// utils
import { getEmptyArray } from "@/utils/common.util";
import { alertUtil } from "@/utils/alert.util";
import { useClusterStore } from "@/stores/cluster.store";
import { useSettingStore } from "@/stores/setting.store";

// services
import { modelAssetService } from "@/services/control-plane/model-asset.service/model-asset.service";

export default defineComponent({
  components: {
    ModelSpecCardSkeleton,
    ModelSpecCard,
  },
  data() {
    return {
      clusterStore: useClusterStore(),
      appStore: useAppStore(),
      settingStore: useSettingStore(),
      permissionStore: usePermissionStore(),
      loading: true as boolean,
      skelatonCards: getEmptyArray(3),
      modelAssets: [] as Array<ModelAsset>,
    };
  },
  created() {
    this.appStore.setPageLoading(false);
    this.loadModelsData();
  },
  computed: {
    filteredModelAssets(): Array<ModelAsset> {
      let modelAssets = this.modelAssets;
      if (!this.allowDeployingGPT2ModelEnabled) {
        modelAssets = this.modelAssets.filter((model) => {
          return model.spec.license == "";
        });
      }

      return modelAssets.sort((a: ModelAsset, b: ModelAsset) =>
        (a.spec?.title?.toLowerCase() || "").localeCompare(b.spec?.title?.toLowerCase() || ""),
      );
    },
    allowDeployingGPT2ModelEnabled(): boolean {
      return this.settingStore.isAllowDeployingGPT2ModelEnabled;
    },
    shouldDisableDeployBtn(): boolean {
      return !this.canCreateInference;
    },
    canCreateInference(): boolean {
      return this.permissionStore.hasPermission(ResourceType.Inferences, Action.Create);
    },
    disableDeployBtnReason(): string {
      if (!this.canCreateInference) {
        return "You don't have permissions to deploy. Contact your admin";
      }
      return "";
    },
  },
  methods: {
    async loadModelsData(): Promise<void> {
      try {
        this.loading = true;
        this.modelAssets = await modelAssetService.list();
      } catch (error: unknown) {
        this.$q.notify(alertUtil.getError("Failed to load data"));
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
