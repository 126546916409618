<template>
  <div class="layout-wrapper">
    <template v-for="(group, index) in widgetsLayout" :key="index">
      <div class="row wrap">
        <div v-for="widget in group" :key="widget" :class="['widget-pa-xs', getColClass(group.length)]">
          <component
            :aid="getWidgetAid(widget)"
            :is="widget"
            :cluster-id="clusterId"
            :node-pool-name="nodePoolName"
            :filter-by-dates="filterByDates"
            @date-changed="$emit('date-changed', $event)"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { EWidgetComponent } from "@/models/chart.model";
import type { IRangeDates } from "@/utils/date.util";
//stat widgets
import { RunningWorkloadsWidget } from "@/components/dashboard-v2/widgets/stat-widgets/running-workloads-widget";
import { PendingWorkloadsWidget } from "@/components/dashboard-v2/widgets/stat-widgets/pending-workloads-widget";
import { ReadyNodesWidget } from "@/components/dashboard-v2/widgets/stat-widgets/ready-nodes-widget/";
import { AllocatedGpuWidget } from "@/components/dashboard-v2/widgets/stat-widgets/allocated-gpu-widget/";
import { ReadyGpuWidget } from "@/components/dashboard-v2/widgets/stat-widgets/ready-gpu-widget/";
//chart widgets
import { WorkloadStatusWidget } from "@/components/dashboard-v2/widgets/chart-widgets/workload-status-widget";
import { NodePoolFreeResourcesWidget } from "@/components/dashboard-v2/widgets/chart-widgets/node-pool-free-resources-widget";
import { WorkloadByTypeWidget } from "@/components/dashboard-v2/widgets/chart-widgets/workload-by-type-widget/";
import { GpuAllocationRatioWidget } from "@/components/dashboard-v2/widgets/chart-widgets/gpu-allocation-ratio-widget/";
import { IdleAllocatedGpuWidget } from "@/components/dashboard-v2/widgets/stat-widgets/idle-allocated-gpu-widget";
//time range widgets
import { OverviewTimeRangeWidgets } from "@/components/dashboard-v2/overview/overview-time-range-widgets";
//grid widgets
import { IdleGpuWorkloadWidget } from "@/components/dashboard-v2/widgets/grid-widget/idle-gpu-workload-widget";
import { OrgUnitRankingWidget } from "@/components/dashboard-v2/widgets/grid-widget/org-unit-ranking-widget";
//model
import { stringUtil } from "@/utils/string.util";

export default defineComponent({
  name: "widget-layout",
  components: {
    ReadyGpuWidget,
    AllocatedGpuWidget,
    ReadyNodesWidget,
    GpuAllocationRatioWidget,
    NodePoolFreeResourcesWidget,
    WorkloadByTypeWidget,
    WorkloadStatusWidget,
    PendingWorkloadsWidget,
    RunningWorkloadsWidget,
    IdleAllocatedGpuWidget,
    OverviewTimeRangeWidgets,
    IdleGpuWorkloadWidget,
    OrgUnitRankingWidget,
  },
  emits: ["date-changed"],
  props: {
    widgetsLayout: {
      type: Array as PropType<EWidgetComponent[][]>,
      required: true,
    },
    clusterId: {
      type: String as PropType<string>,
      required: true,
    },
    nodePoolName: {
      type: String as PropType<string>,
      required: false,
    },
    filterByDates: {
      type: Object as PropType<IRangeDates>,
      required: false,
    },
  },
  methods: {
    getColClass(widgetCount: number): string {
      let columnSize = Math.floor(12 / widgetCount);

      if (columnSize === 2) {
        return "col";
      }
      if (columnSize === 6) {
        columnSize = 12;
      }

      return `col-md col-sm-${columnSize}`;
    },
    getWidgetAid(widgetName: string): string {
      return stringUtil.camelCaseToHyphenated(widgetName);
    },
  },
});
</script>

<style scoped lang="scss">
.widget-pa-xs {
  padding: 3px 3px;
}
.layout-wrapper {
  padding: 3px;
}
</style>
