import type { ITableColumn } from "@/models/table.model";
import type { DepartmentGetRanking, ProjectGetRanking } from "@/swagger-models/org-unit-service-client";
import { numberFormat } from "@/utils/format.util";
import { ECustomCell } from "@/models/table.model";
import { orgUnitUtil } from "@/utils/org-unit.util";

/** Ranking **/
export enum EOrgUnitRankingColumnName {
  AllocationValue = "allocationValue",
  UtilizationValue = "utilizationValue",
  WorkloadsCount = "workloadsCount",
}

export enum EOrgUnitProjectsRankingColumnName {
  Project = "project",
  ParentName = "parentName",
}

export enum EOrgUnitDepartmentsRankingColumnName {
  Department = "department",
}

export enum EOrgUnitRankingColumnLabel {
  AllocationValue = "Avg. GPU devices allocation",
  UtilizationValue = "Avg. Gpu utilization",
  WorkloadsCount = "Workloads",
}

export enum EOrgUnitProjectsRankingColumnLabel {
  Project = "Project",
  ParentName = "Department",
}

export enum EOrgUnitDepartmentsRankingColumnLabel {
  Department = "Department",
}

export interface IOrgUnitRankingTable
  extends Pick<ProjectGetRanking, "allocationValue" | "utilizationValue" | "name">,
    Pick<DepartmentGetRanking, "allocationValue" | "utilizationValue" | "name"> {
  workloadsCount?: number;
}

export const allOrgUnitRankingColumnsMap: Record<string, ITableColumn> = {
  [EOrgUnitProjectsRankingColumnName.Project]: {
    name: EOrgUnitProjectsRankingColumnName.Project,
    label: EOrgUnitProjectsRankingColumnLabel.Project,
    field: (row: IOrgUnitRankingTable) => row.name,
    display: true,
    align: "left",
  },
  [EOrgUnitProjectsRankingColumnName.ParentName]: {
    name: EOrgUnitProjectsRankingColumnName.ParentName,
    label: EOrgUnitProjectsRankingColumnLabel.ParentName,
    field: (row: ProjectGetRanking) => row.parentName,
    display: true,
    align: "left",
  },
  [EOrgUnitDepartmentsRankingColumnName.Department]: {
    name: EOrgUnitDepartmentsRankingColumnName.Department,
    label: EOrgUnitDepartmentsRankingColumnLabel.Department,
    field: (row: IOrgUnitRankingTable) => row.name,
    display: true,
    align: "left",
  },
  [EOrgUnitRankingColumnName.AllocationValue]: {
    name: EOrgUnitRankingColumnName.AllocationValue,
    label: EOrgUnitRankingColumnLabel.AllocationValue,
    field: (row: IOrgUnitRankingTable) =>
      row.allocationValue !== null ? parseFloat(numberFormat(row.allocationValue)) : "-",
    display: true,
    align: "left",
  },
  [EOrgUnitRankingColumnName.UtilizationValue]: {
    name: EOrgUnitRankingColumnName.UtilizationValue,
    label: EOrgUnitRankingColumnLabel.UtilizationValue,
    field: (row: IOrgUnitRankingTable) =>
      row.utilizationValue !== null ? parseFloat(row.utilizationValue.toFixed(0)) : null,
    format: (utilizationValue: number | null) => orgUnitUtil.getProgressColOptions(utilizationValue),
    customCell: ECustomCell.PROGRESS_COL,
    display: true,
    align: "left",
  },
  [EOrgUnitRankingColumnName.WorkloadsCount]: {
    name: EOrgUnitRankingColumnName.WorkloadsCount,
    label: EOrgUnitRankingColumnLabel.WorkloadsCount,
    field: (row: IOrgUnitRankingTable) => row.workloadsCount || "-",
    display: true,
    align: "left",
  },
};

export const projectsRankingColumns: ITableColumn[] = [
  { ...allOrgUnitRankingColumnsMap.project },
  { ...allOrgUnitRankingColumnsMap.parentName },
  { ...allOrgUnitRankingColumnsMap.allocationValue },
  { ...allOrgUnitRankingColumnsMap.utilizationValue },
  { ...allOrgUnitRankingColumnsMap.workloadsCount },
];

export const departmentsRankingColumns: ITableColumn[] = [
  { ...allOrgUnitRankingColumnsMap.department },
  { ...allOrgUnitRankingColumnsMap.allocationValue },
  { ...allOrgUnitRankingColumnsMap.utilizationValue },
  { ...allOrgUnitRankingColumnsMap.workloadsCount },
];
